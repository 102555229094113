
import WebService from "./AccountWebService";

const AccessService = {
    checkversion() {
        if (localStorage.getItem('X-Token') !== undefined && localStorage.getItem('X-Token') != null && localStorage.getItem('X-Token') !== "null") {
            var body = {
                version: localStorage.getItem('X-Token'),
                p_user_id: localStorage.getItem('p_user_id')
            }
            return WebService.putAPI({
                action: `user-website/version`,
                body: body,
            }).then((res: any) => {
                localStorage.setItem('version', localStorage.getItem('X-Token') ?? "")
                localStorage.removeItem('X-Token')
            }).catch((error) => {
                return error;
            });
        }
    },
    checkAccess(code: any, type = "LIST") {
        var respone = {
            "can_read": 1,
            "can_write": 1,
            "can_full_access": 1,
        }
        return respone;
        // var data1 = atob(localStorage.getItem('udid'));
        var data1 = atob(localStorage.getItem('udid') ?? "");
        if (localStorage.getItem('udid') !== undefined && localStorage.getItem('udid') !== 'undefined') {
            var accesses = JSON.parse(data1);
            if (accesses) {
                accesses.forEach((m_element: any) => {
                    if (m_element.code === code) {
                        respone = {
                            "can_read": m_element.can_read,
                            "can_write": m_element.can_write,
                            "can_full_access": m_element.can_full_access,
                        };
                    } else if (m_element.sub_menus.length > 0) {
                        m_element.sub_menus.forEach((s_element: any) => {
                            if (s_element.code === code) {
                                respone = {
                                    "can_read": s_element.can_read,
                                    "can_write": s_element.can_write,
                                    "can_full_access": s_element.can_full_access,
                                };
                            } else {
                                if (s_element.super_sub_menus.length > 0) {
                                    s_element.super_sub_menus.forEach((ss_element: any) => {
                                        if (ss_element.code === code) {
                                            respone = {
                                                "can_read": ss_element.can_read,
                                                "can_write": ss_element.can_write,
                                                "can_full_access": ss_element.can_full_access,
                                            };
                                        }
                                    });
                                }
                            }
                        });
                    }
                })
            };
            return respone;
        }
    },
    checkFeature(page_type: any, count: any, type: any, code: any, access_type: any, callback: any) {
        //this.checkversion();
        let obj = {
            type: page_type,
            p_user_id: localStorage.getItem('p_user_id') ?? "",
            platform: localStorage.getItem('platform')?.toUpperCase() ?? ""
        };
        var qurey = new URLSearchParams(obj).toString();
        return WebService.getAPI({
            action: `subscriptions/` + localStorage.getItem('platform')?.toLowerCase() + `/check-feature?` + qurey,
            body: null,
        }).then((res: any) => {            
            // if (res.subscription === "N") {
            //     var obj = res.list[0];
            //     obj.subscription=false
            //     obj.showModal = true;
            //     callback(obj);
            // } else {
                var obj = res.list[0];
                if (obj == undefined) {
                    obj = {};
                }
                obj.subscription=true
                obj.is_back = false;
                obj.is_access_denied = false;
                if (!obj.is_available) {
                    obj.flag = false;
                    if (type === 'ACCESS') {
                        obj.showModal = true;
                        obj.is_back = true;
                        localStorage.setItem('upgrade', "BACK");
                    }
                } else {
                    if (type === 'COUNT') {
                        if (!obj.is_unlimited && obj.max_limit <= count) {
                            obj.showModal = true;
                            localStorage.setItem('upgrade', "BACK");
                            obj.flag = true;
                        } else {
                            obj.flag = true;
                        }
                    } else {
                        obj.flag = true;
                    }
                }                
                if (obj.flag) {
                    obj.access = this.checkAccess(code, access_type)
                    if (!obj.access.can_write && !obj.access.can_full_access && !obj.access.can_read) {
                        obj.is_access_denied = true
                        //   this.router.navigate(['/access-denied'], { skipLocationChange: true })
                    }
                    if (access_type === "VIEW") {
                        if (obj.access.can_read && !obj.access.can_write && !obj.access.can_full_access) {
                            obj.is_access_denied = true
                        }
                    }
                }
                callback(obj);
            // }
        }).catch((error) => {
            callback(false);
            return error;
        });
    },
}

export default AccessService;