import { useEffect, useState } from "react";
import React, { useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  FloatingLabel,
  Offcanvas,
  FormCheck,
  InputGroup
} from "react-bootstrap";
import { BsSave } from "react-icons/bs";
import AsyncSelect from "react-select/async";
import { useForm } from "react-hook-form";
import WebService from "../../../Services/WebService";
import { Label } from "../../Common/Label/Label";
import { toast } from "react-toastify";
import profileImg from "../../../assets/svg/company-default.svg";
import { MdClose, MdModeEditOutline } from "react-icons/md";
import NoImage from "../../../assets/images/noImage.png"
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../Brands/brand.scss";

var abortController = new AbortController();
var isPending = false;
const TabGenral = (props: any) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm();
  var watchvariable = watch();
  const [selectedImage, setSelectedImage] = useState(profileImg);
  const [currency, setCurrancy] = useState("");
  const [chatVisibility, setChatVisibility] = useState('ALL');
  const [reassign, setReassign] = useState('ACCOUNT_ADMIN');
  const [currancyFlag, setcurrancyFlag] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [viewUploadImg, setViewUploadImg] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const [uploaded, setUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [countryFlag, setCountryFlag] = useState(false);
  const [country_code, setCountryCode] = useState("us");
  const [country, setCountry] = useState("United States");
  const [dial_code, setDialCode] = useState("1");
  const [autoCloseChat, setAutoCloseChat] = useState(false);

  const inputFile = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (uploaded) {
      setValue("logo", watchvariable.logo);
    } else {
      if (props.data.logo === null) {
        watchvariable.logo = profileImg;
      } else {
        watchvariable.logo = props.data.logo;
      }
      setUploaded(false);
      setSelectedImage(watchvariable.logo);
      setValue("logo", watchvariable.logo);
    }
    watchvariable.brand_name = props.data.brand_name;
    // watchvariable.currency =
    //   props.data.currency?.symbol + "(" + props.data.currency?.code + ")";
    watchvariable.date_format = props.data.date_format;
    setValue("brand_name", watchvariable.brand_name);
    setIsLoading(true)
    setChatVisibility(props.data.chat_visibility ? props.data.chat_visibility : 'ALL')
    setReassign(props.data.re_assign ? props.data.re_assign : "ACCOUNT_ADMIN")
    setSelectedCurrency({
      label:
        props.data.currency?.symbol + "(" + props.data.currency?.code + ")",
      value: props.data.currency?.id,
      symbol: props.data.currency?.symbol,
    });
    setCountry(props.data.country ? props.data.country : 'United States')
    setCountryCode(props.data.country_code ? props.data.country_code : 'us')
    setDialCode(props.data.dial_code ? props.data.dial_code : '1')
    watchvariable.currency = props.data.currency?.symbol;
    setValue("currency", watchvariable.currency);
    setIsLoading(false)
    setValue("date_format", watchvariable.date_format);
    setValue("auto_chat_close_hour", props?.data?.auto_chat_close_hour ? props?.data?.auto_chat_close_hour : '');
    setAutoCloseChat(props?.data?.is_auto_chat_close === 1 ? true : false);
  }, [props]);
  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setIsLoading(true)
      if (isPending) {
        return abortController.abort();
      }
      abortController = new AbortController();
      isPending = true;
      return WebService.getAPI({
        action: `currency/list?keyword=` + inputValue,
        body: null,
        signal: abortController.signal
      })
        .then((res: any) => {
          if (res) {
            var updatedOptions = res.map((currency: any) => ({
              value: currency.id,
              label: currency.symbol + "(" + currency.code + ")",
              symbol: currency.symbol,
            }));
            isPending = false;
            resolve(updatedOptions);
          } else {
            isPending = false;
            resolve([]);
          }
          setIsLoading(false)
        })
        .catch((error) => {
          isPending = false;
          resolve([]);
          return error;
        });
    });

  const changeBrand = (value: any) => {
    watchvariable.brand_name = value;
    setValue("brand_name", watchvariable.brand_name);
  };
  const onCurrencySelection = (selectedOption: any) => {
    setSelectedCurrency(selectedOption);
    setcurrancyFlag(false);
    watchvariable.currency = selectedOption.symbol;
    setValue("currency", watchvariable.currency);
  };
  const UpdateGeneralData = () => {
    WebService.addLoader("save");
    watchvariable.type = localStorage.getItem("atype");
    watchvariable.id = props.data.id;
    watchvariable.app_type = localStorage.getItem("platform");
    watchvariable.chat_visibility = chatVisibility
    watchvariable.re_assign = reassign
    watchvariable.country = country;
    watchvariable.country_code = country_code;
    watchvariable.dial_code = dial_code;
    watchvariable.is_auto_chat_close = autoCloseChat ? 1 : 0
    return WebService.putAPI({
      action: `user-website/update`,
      body: watchvariable,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        localStorage.setItem("brand_name", watchvariable.brand_name);
        localStorage.setItem("date_format", watchvariable.date_format);
        localStorage.setItem("currency", watchvariable.currency);
        WebService.removeLoader("save");
      })
      .catch((error) => {
        WebService.removeLoader("save");
        return error;
      });
  };
  const handleError = (errros: any) => {
    setcurrancyFlag(false);
    if (currency === "" || currency === undefined) {
      setcurrancyFlag(true);
    }
    return false;
  };
  const uploadImagePopup = () => {
    setViewUploadImg(true);
  };
  const closeUploadImgPopup = () => {
    setViewUploadImg(false);
  };
  const uploadImage = () => {
    WebService.addLoader("upload");
    if (!imageFile) {
      toast.error("Please upload image");
      WebService.removeLoader("upload");
    } else {
      return WebService.uploadAPI({
        action: `user-website/upload-image`,
        body: null,
        _file: imageFile,
        key: "image",
        isShowError: true,
      })
        .then((res: any) => {
          WebService.removeLoader("upload");
          // setSelectedImage(res.path)
          watchvariable.logo = res.path;
          setValue("logo", watchvariable.logo);
          closeUploadImgPopup();
          setUploaded(true);
          props.sendData(props.data.id);
          toast.success(res.message);
        })
        .catch((error) => {
          WebService.removeLoader("upload");
          return error;
        });
    }
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    if (inputFile.current != null) inputFile.current.click();
  };
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file.size > 2101546) {
      toast.error("Image size should be less than 2 MB");
      WebService.removeLoader("upload");
    } else if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      setImageFile(file);
    }
  };

  const changePhone = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => {
    setCountryCode(country.countryCode)
    setCountry(country.name)
    setDialCode(country.dialCode)
    watchvariable.country = country.name
    watchvariable.country_code = country.countryCode
    watchvariable.dial_code = country.dialCode
    setCountryFlag(false)
  };
  return (
    <>
      <Card className="border-0">
        <Form
          className="wmx-form-style"
          name="Verify1"
          id="Verify1"
          onSubmit={handleSubmit(UpdateGeneralData, handleError)}
        >
          <Card.Header className="profile-bg text-center border-0">
            <div className="profile-img">
              <img
                alt=""
                src={watchvariable.logo}
                onError={(error: any) =>
                (error.target.src =
                  NoImage)
                }
                className=" rounded-circle object-fit-cover mx-auto mb-1"
                width="80"
                height="80"
              />
              <a className="edit-option" onClick={uploadImagePopup}>
                <MdModeEditOutline size={16} cursor={"pointer"} />
              </a>
            </div>
          </Card.Header>
          <Card.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <FloatingLabel label={t('Brands.Brands_Name')}>
                    <Form.Control
                      {...register("brand_name", { required: true })}
                      type="text"
                      maxLength={30}
                      id="brand"
                      placeholder="Enter your brand name"
                      value={watchvariable.brand_name}
                      onChange={(e) => {
                        changeBrand(e.target.value);
                      }}
                    />
                    {errors.brand_name && (
                      <Label title={t('Common.Brand_Name_Err_Msg')} modeError={true} />
                    )}
                  </FloatingLabel>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3 dropload">
                  <AsyncSelect
                    {...register("currency", { required: true })}
                    id="currency"
                    isLoading={isLoading}
                    cacheOptions
                    defaultOptions
                    onChange={onCurrencySelection}
                    loadOptions={promiseOptions}
                    placeholder={t('Common.Select_Currency')}
                    value={selectedCurrency}
                  />

                  {(selectedCurrency === undefined || selectedCurrency === "") &&
                    currancyFlag ? (
                    <Label title={t('Common.Select_Currency_Err_Msg')} modeError={true} />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <FloatingLabel controlId="DateFormat" label={t('Brands.Date_Format')}>
                    <Form.Select
                      value={watchvariable.date_format}
                      {...register("date_format", { required: true })}
                      aria-label="Select i"
                    >
                      <option value="">{t('Common.Selct')}</option>
                      <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                      <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                      <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                    </Form.Select>
                  </FloatingLabel>
                  {errors.date_format && (
                    <Label title={t('Common.Select_Date_Err_Msg')} modeError={true} />
                  )}
                </div>
              </Col>

            </Row>
            <Row>
              <Col lg={6} className="mb-4 setup-country-dd">
                <PhoneInput
                  country={country_code || "us"}
                  placeholder='Phone'
                  onChange={changePhone}
                  inputClass="inputClass123"
                />
                <input className="coutnryCls" value={"(+" + dial_code + ") " + country} disabled type="text" />
                {(country === undefined || country === "") &&
                  countryFlag ? (
                  <Label
                    title={t('Please select country')}
                    modeError={true}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <p className='font-14'>{t('Inbox Visibility')}:</p>
              </Col>
              <Col lg={6} className="d-flex gap-2">
                <FormCheck
                  type="radio"
                  name="visibility"
                  label={t('All Agents')}
                  value="ALL"
                  checked={chatVisibility === "ALL" ? true : false}
                  onClick={(e: any) => {
                    setChatVisibility('ALL')
                  }}
                />
                <FormCheck
                  type="radio"
                  name="visibility"
                  label={t('Assign Agents')}
                  value="ASSIGN_USER"
                  checked={chatVisibility === "ASSIGN_USER" ? true : false}
                  onClick={(e: any) => {
                    setChatVisibility('ASSIGN_USER')
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <p className='font-14'>{t('Re-assign')}:</p>
              </Col>
              <Col lg={6} className="d-flex gap-2">
                <FormCheck
                  type="radio"
                  name="reassign"
                  label={t('Account Admin')}
                  value="ACCOUNT_ADMIN"
                  checked={reassign === "ACCOUNT_ADMIN" ? true : false}
                  onClick={(e: any) => {
                    setReassign('ACCOUNT_ADMIN')
                  }}
                />
                <FormCheck
                  type="radio"
                  name="reassign"
                  label={t('Any Agents')}
                  value="ANY_AGENT"
                  checked={reassign === "ANY_AGENT" ? true : false}
                  onClick={(e: any) => {
                    setReassign('ANY_AGENT')
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <p className='font-14'>{t('Auto-close Chat')}:</p>
              </Col>
              <Col lg={1} className="d-flex gap-2">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  className="cursor-pointer"
                  checked={autoCloseChat}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAutoCloseChat(e.target.checked);
                    } else {
                      setAutoCloseChat(e.target.checked);
                    }
                  }}
                />
              </Col>
              {autoCloseChat && (
                <Col lg={6}>
                  <div className="mb-3">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">After</InputGroup.Text>
                      <Form.Control
                        {...register("auto_chat_close_hour", { 
                          required: t('Please enter chat close hour'), 
                          pattern: {
                            value: /^[0-9]+$/,
                            message: t('Please enter a valid number')
                          },
                          validate: (value) =>
                            parseInt(value, 10) >= 1 || t('Number must be greater than 0')
                        })}
                        type="text"
                        aria-describedby="basic-addon1"
                        id="auto_chat_close_hour"
                        placeholder="Enter hours"
                        value={watchvariable.auto_chat_close_hour}
                        onChange={(e) => {
                          setValue("auto_chat_close_hour", e.target.value);
                          clearErrors("auto_chat_close_hour");
                        }}
                      />
                      <InputGroup.Text id="basic-addon1">Hours</InputGroup.Text>
                    </InputGroup>
                    {errors.auto_chat_close_hour && (
                      <Label title={errors?.auto_chat_close_hour?.message?.toString()} modeError={true} />
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </Card.Body>
          <Card.Footer className="bg-white text-center rounded-bottom-3 border-light py-3">
            <Button className="btn-wmx btn-lg" type="submit" id="save">
              <BsSave size={16} className="me-1" /> {t('Common.Save')}
            </Button>
          </Card.Footer>
        </Form>
      </Card>

      <Offcanvas
        show={viewUploadImg}
        onHide={closeUploadImgPopup}
        placement="end"
        className="righSide-modal size-md"
      >
        {" "}
        <Form
          className="wmx-form-style"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(uploadImage)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t('Common.Brand_Logo')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="wmx-form-style">
              <div className="mb-3 cursor d-flex justify-content-center align-items-center h-100">
                <img
                  src={selectedImage}
                  alt="img"
                  width={350}
                  height={350}
                  className={"center-img"}
                  onClick={onButtonClick}
                />
              </div>
              <div className="mb-3">
                <input
                  type="file"
                  id="img"
                  name="img"
                  accept="image/*"
                  className="w-100"
                  onChange={handleImageUpload}
                  ref={inputFile}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            {/* <Button className="btn  btn-wmx w-100"> Upload image </Button> */}
          </Offcanvas.Body>
          <div className="offcanvas-footer">1
            <Button className="btn btn-wmx-gray" onClick={closeUploadImgPopup}>
              <MdClose size={18} className="icon" /> {t('Common.Close')}
            </Button>

            <Button className="btn-wmx" type="submit" id="upload">
              <BsSave size={16} className="me-1" /> {t('Common.Upload_Logo')}
            </Button>
          </div>
        </Form>
      </Offcanvas>
    </>
  );
};
export default TabGenral;
